<template>
  <v-footer padless>
    <v-card flat tile class="flex background-home">
      <v-card-text class="py-1 text-center white--text">
        <v-btn small class="btn-link" color="white" text :href="links.webpage" target="_blank">
          <v-row no-gutters>
            <v-col align-self="center" color="white"><v-img class="ma-0 pa-0" :src="tecnologias" width="30" height="30"/></v-col>
            <v-col align-self="center" class="ml-2">TEFLEX</v-col>           
          </v-row>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'mainFooter',
  data: () => ({
    mark: process.env.VUE_APP_MARK,
    links: {
      webpage: process.env.VUE_APP_WEBPAGE
    },
    tecnologias: require('@/assets/tesf.gif'),
  })
}
</script>

<style scoped>
  .background-home {
    background-color: #263238 !important;
  }
  .btn-link {
    text-transform: none;
  }
</style>
